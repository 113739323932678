import RailsUJS from "@rails/ujs";
import * as RailsActiveStorage from "@rails/activestorage";

import { createRoot } from "react-dom/client";

// Started automatically in production, but not in development?
// See if this is fixed in newer versions
if(!window._rails_loaded) RailsUJS.start()

RailsActiveStorage.start();

import "../../assets/stylesheets/application.scss";

const components = import.meta.glob("../components/*.tsx", {
  import: "default",
});

document.querySelectorAll("div[data-component]").forEach(async (element) => {
  const stringProps = element.getAttribute("data-props");
  const componentName = `../components/${element.getAttribute("data-component")}.tsx`;

  if (!stringProps) return;

  const componentProps = JSON.parse(stringProps);

  if (!Object.hasOwn(components, componentName)) return;

  const component = components[componentName];

  const Component = (await component()) as React.ComponentType;

  const root = createRoot(element);

  root.render(<Component {...componentProps} />);
});
